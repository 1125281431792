import React from "react";
import { Content } from "antd/lib/layout/layout";
import { List, Card } from "antd";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { Image } from "antd";

import {
  FilePptOutlined,
  LikeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import IndustrySpecialImage from "src/components/products/images/industrySpecial.jpg";
import ThermoProtectImage from "src/components/products/images/thermoProtect.jpg";
import ThermoActiveImage from "src/components/products/images/thermoActive.jpg";
import ThermoPlusImage from "src/components/products/images/thermoPlus.jpg";
import ThermoVitalImage from "src/components/products/images/thermoVital.jpg";
import LumenImage from "src/components/products/images/lumen.jpg";

import { Link } from "gatsby";

const { Meta } = Card;

const data = [
  {
    src: ThermoProtectImage,
    title: (
      <span className="thermoProtect colored centered">ThermoProtect</span>
    ),
    description: "Exteriores",
    linkProduct: "/productos/thermoprotect",
    linkReferencias: (
      <Link to="/referencias/exterior/">
        <LikeOutlined />,
      </Link>
    ),
    download: (
      <a
        href="/thermoProtect/climateCoating-thermoProtect-presentacion.pdf"
        download
      >
        <DownloadOutlined />
      </a>
    ),
  },
  {
    src: ThermoActiveImage,
    title: <span className="thermoActive  colored centered">ThermoActive</span>,
    description: "Cubiertas/Tejados",
    linkProduct: "/productos/thermoactive/",
    download: (
      <a
        href="/thermoActive/climateCoating-thermoActive-presentacion.pdf"
        download
      >
        <DownloadOutlined />
      </a>
    ),
    linkReferencias: (
      <Link to="/referencias/cubiertas/">
        <LikeOutlined />,
      </Link>
    ),
  },
  {
    src: ThermoPlusImage,
    title: <span className="thermoPlus colored centered">ThermoPlus</span>,
    description: "Interiores",
    linkProduct: "/productos/thermoplus/",
    linkReferencias: (
      <Link to="/referencias/interior/">
        <LikeOutlined />,
      </Link>
    ),
    download: (
      <a href="/thermoPlus/climateCoating-thermoPlus-presentacion.pdf" download>
        <DownloadOutlined />
      </a>
    ),
  },
  {
    src: ThermoVitalImage,
    title: <span className="thermoVital colored centered">ThermoVital</span>,
    description: "Interiores con humedades",
    linkProduct: "/productos/thermovital/",
    linkReferencias: (
      <Link to="/referencias/interior/">
        <LikeOutlined />,
      </Link>
    ),
    download: (
      <a
        href="/thermoVital/climateCoating-thermoVital-presentacion.pdf"
        download
      >
        <DownloadOutlined />
      </a>
    ),
  },
  {
    src: IndustrySpecialImage,
    title: (
      <span className="industrySpecial colored centered">IndustrySpecial</span>
    ),
    download: (
      <a
        href="/industrySpecial/climateCoating-industrySpecial-presentacion.pdf"
        download
      >
        <DownloadOutlined />
      </a>
    ),
    description: "Uso Industrial",
    linkProduct: "/productos/industryspecial/",
    linkReferencias: (
      <Link to="/referencias/industrial/">
        <LikeOutlined />,
      </Link>
    ),
  },
  {
    src: LumenImage,
    title: <span className="lumen colored centered">Lumen</span>,
    download: (
      <a href="/lumen/climateCoating-lumen-presentacion.pdf" download>
        <DownloadOutlined />
      </a>
    ),
    description: "Luminosidad para interiores",
    linkProduct: "/productos/lumen/",
    linkReferencias: (
      <Link to="/referencias/lumen/">
        <LikeOutlined />,
      </Link>
    ),
  },
];

export const ProductosGeneral = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      pageName: "Contacto",
      showPageTitle: false,
      pageTitle: "Productos de ClimateCoating España",
    }}
  >
    <Content className="page productGallery">
      <h1 className="pageTitle centered"> Productos de ClimateCoating</h1>
      <span
        className="subTitlePage centered"
        style={{
          color: "#666",
          textAlign: "center",
          fontSize: "18px",
          maxWidth: "98%",
          width: "1000px",
          marginBottom: "35px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        Todos los productos siguen el principio de{" "}
        <strong style={{ color: "#d6202d" }}>
          Membrana Termocerámica con rendimiento endotérmico
        </strong>{" "}
        y varían en aditivos específicos para un comportamiento óptimo en cada
        escenario.
      </span>

      <div
        style={{
          maxWidth: "98%",
          width: "1200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <List
          grid={{
            gutter: 30,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={data}
          renderItem={({
            src,
            title,
            description,
            linkProduct,
            download,
            linkReferencias,
          }) => (
            <List.Item>
              <Card
                style={{ width: 300, marginLeft: "auto", marginRight: "auto" }}
                cover={<Image {...{ src }} />}
                actions={[
                  <Link to={linkProduct}>
                    <FilePptOutlined />
                  </Link>,
                ]
                  .concat(download || [])
                  .concat(linkReferencias || [])
                  .filter((f) => f)}
              >
                <Meta
                  {...{
                    title,
                    description,
                  }}
                />
              </Card>
            </List.Item>
          )}
        />
        <br />
        <br />
      </div>
    </Content>
  </LayoutGeneral>
);

export default ProductosGeneral;
